.filter {
    width: 100%;
    margin-bottom: 42px;
}

.lineContainer {
    filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.2));
    border-radius: 19px;
}

.paginationContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.fab {
    background-color: #FAFAFA !important;
    border-radius: 19px !important;
    height: 56px;
    width: 56px;
    filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.2));
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #54B8AE !important;
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    border: none !important;
}
