.container {
    height: calc(100vh - 72px);
    padding: 14vh 0;;
}

.innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
}

.card {
    margin: 0 3vw !important;
    background-color: #FAFAFA !important;
    color: #54B8AE !important;
    border-radius: 19px !important;
    filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.2));
    height: 100%;
    flex: 1 1 0;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-evenly !important;
}

.card span {
    font-size: 24px;
}
