@media (max-width: 1080px) {
    .container {
        padding-bottom: 96px;
    }
}

@media(max-width: 960px) {
    .container {
        margin-top: 20px;
    }
}

.filter {
    width: 100%;
    margin-bottom: 42px;
}

.lineContainer {
    filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.2));
    border-radius: 19px;
}

.userLine {
    height: 56px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: #FAFAFA;
    padding: 0 42px 0 54px;
    justify-content: space-between;
}

.userLine:first-of-type {
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
}

.userLine:last-of-type {
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
}

.username {
    flex-grow: 1;
    font-weight: bold;
    color: #424242;
}

.email {
    flex-grow: 2;
    color: #424242;
}

@media (max-width: 500px) {
    .email {
        display: none;
    }
}

.actions {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #54B8AE;
}

.color {
    color: #54B8AE;
}

.paginationContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.fab {
    background-color: #FAFAFA !important;
    border-radius: 19px !important;
    height: 56px;
    width: 56px;
    filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.2));
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #54B8AE !important;
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    border: none !important;
}

.loadingContainer {
    display: flex;
    justify-content: center;
}
