.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.space {
    height: 10px;
}

.loginButtonContainer {
    width: calc(min(100%, 368px));
}

.loginButton {
    font-family: Roboto, sans-serif;
    height: 56px;
    padding: 1px 2px !important;
    filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.2));
    border: none !important;
    border-radius: 19px !important;
    color: #54B8AE !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    margin-top: 30px !important;
    background-color: #FAFAFA !important;
    text-transform: uppercase;
    transition: all 200ms ease;
    width: 100%;
}

.loading {
    cursor: default !important;
}

.loginButton:active, .loading {
    filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.1));
}

.separatorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.separator {
    width: 30px;
    height: 2px;
    background-color: #54B8AE;
    border-radius: 2px;
    margin: 20px 0;
}

.contactButton {
    width: 368px;
    height: 27px;

    background: #FAFAFA;
    box-shadow: 0 5px 34px rgba(0, 0, 0, 0.1);
    border-radius: 45px;
    border: none;

    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    color: #54B8AE;
}

.error {
    color: #FF6969;
    width: 100%;
    text-align: center;
}


