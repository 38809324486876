.tabContainer {
    margin-top: 24px;
    overflow: hidden;
    min-height: 48px;
    display: flex;
    background-color: #fafafa;
    border-radius: 19px;
    box-shadow: 0 5px 34px rgba(0, 0, 0, 0.1);
}

.tabIndicator {
    display: flex;
    justify-content: center;
    background-color: transparent !important;
}

.innerTabIndicator {
    width: 40px;
    background-color: #54B8AE;
}

.tabItem {
    font-size: 16px !important;
    padding: 16px 30px !important;
    text-transform: none !important;
}

.contentContainer {
    margin-top: 36px;
}
