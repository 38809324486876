.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18vh;
}

.title {
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
}

@media (max-height: 750px) {
    .container {
        margin-top: 9vh;
    }
}

@media (max-height: 620px) {
    .container {
        margin-top: 0;
    }
}

@media (max-width: 480px) {
    .container {
        margin-top: 5vh;
    }
}
