.button {
    font-family: Roboto, sans-serif;
    height: 46px;
    border: none !important;
    border-radius: 15px !important;
    color: #54B8AE !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    margin-top: 30px !important;
    background-color: #FAFAFA !important;
    text-transform: uppercase;
    transition: all 200ms ease;
    width: calc(min(140px, 100%));
    z-index: 100;
    position: relative;
}

.button:focus {
    background-color: #F5F5F5 !important;
}

.input:not(:first-of-type) {
    margin-top: 10px;
}

.input {
    width: 100%;
}
.primaryButton {
    background-color: #CCEAE7;
}

.container {
    border-radius: 19px !important;
    padding: 24px;
    background-color: #FAFAFA !important;
}
