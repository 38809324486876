.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #FAFAFA;
    padding: 12px 0;
}

.shadow {
    filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.2));
}

.logo {
    margin: 0 36px;
}

.button {
    color: #54B8AE !important;
    padding: 8px 25px !important;
    border-radius: 12px !important;
    font-family: Roboto, serif;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
}

.navbarEnd {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #54B8AE !important;
}

.burger {
    display: none;
}

@media(max-width: 750px) {
    .desktopOnly {
        display: none;
    }
}

@media (min-width: 751px) {
    .mobileOnly {
        display: none;
    }
}

.marginRight {
    margin-right: 24px;
}

.navbarEnd a {
    text-decoration: none;
}

.title {
    display: block;
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
}

.subtitle {
    display: block;
    font-family: 'jr!hand', serif;
    font-size: 14px;
    line-height: 19px;
    margin-top: -6px;
    margin-left: 75px;
}

.icon {
    color: #54B8AE !important;
    margin-right: 36px !important;
}

.mobileMenu {
    background-color: #FAFAFA;
}

.menuText {
    opacity: 0.5;
}

.link {
    color: inherit;
    text-decoration: none !important;
}
