.container {
    margin-top: 84px;
}

@media(max-width: 960px) {
    .container {
        margin-top: 20px;
    }
}

.tabs {
    margin-bottom: 24px;
}
