html {
  font-family: 'Roboto', sans-serif;
  background-color: #FAFAFA;
}

.primary-color  {
  color: #54B8AE;
}

@font-face {
  font-family: 'jr!hand';
  src: url(./fonts/Jrhand.woff2) format('woff2');
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.waves-effect > .waves-ripple {
  background-color: #54B8AE;
}

body {
  margin: 0;
}

.snackbar {
  height: 40px;
  min-width: 348px;
  border-radius: 19px !important;
  filter: drop-shadow(0px 5px 34px rgba(0, 0, 0, 0.2));
  box-shadow: none !important;
  background-color: #424242 !important;
  color: white !important;
}

.snackbar-inner {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}

.snackbar-button {
  color: #4DB6AC;
  width: auto;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  background-color: transparent;
  border: none;
}
