.container {
    display: flex;
    flex-direction: column;
    width: calc(min(368px, 100%));
}

.inputLabel {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    color: #54B8AE;
    margin-bottom: 4px;
}

.inputContainer {
    border-radius: 19px;
    display: flex;
    align-items: center;
}


.inputContainer input {
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    margin: 0 20px;
    font: 400 18px Roboto;
}

.inputContainer:focus {
    box-shadow: 0 0 2px 0 #54B8AE;
}
