.title {
    display: block;
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 96px;
    line-height: 112px;
}

.subtitle {
    display: block;
    font-family: 'jr!hand', serif;
    font-size: 48px;
}

@media (min-width: 780px) {
    .subtitle {
        margin-top: -20px;
        margin-left: 308px;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 64px;
        line-height: 75px;
        margin: 0 48px;
    }

    .subtitle {
        font-size: 36px;
        line-height: 48px;
        margin: 0 24px;
    }
}
